@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.navbar_wrapper{
    display: flex;
    justify-content: space-between;

    &.navbar_wrapper_desktop{
        display: none;

        @include media-breakpoint-up(lg){
            display: flex;
        }
    }

    &.navbar_wrapper_mobile{
        display: flex;

        @include media-breakpoint-up(lg){
            display: none;
        }
    }

    .contact_wrapper{
        display: flex;
        justify-content: flex-end;
    }

    .logo_wrapper{
        justify-content: flex-start;
        display: flex;

        @include media-breakpoint-up(lg){
            justify-content: center;
        }
    }
    .picture_wrapper{
        min-width: 20%;
        max-width: 100%;

        width:40%;
        height: auto;
        @include media-breakpoint-up(lg){
            max-width: 30%;
        }
    }
    .menu_wrapper{
     
        display: flex;
    };
    .button_wrapper{
        display: flex;
        justify-content: flex-end;
        height:120px;
    }
}
