body {
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.carousel-control-prev-icon {
    background-color: #0c0c0c !important;
}

.carousel-control-next-icon {
    background-color: black !important;
}

@font-face {
    font-family: 'HolidayFree';
    src: local('HolidayFree'), url(./Assets/fonts/HolidayFree.otf) format('opentype');
    
}
.button 
{
    width:100%;
    height:150%;
}
.contact_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:15px;
    align-items: center;
}
.contact_item img {
    width:60px;
    display: flex;
    top: 50%;
    left: 50%;
}
.media_item img {
    width:30px;
    display: flex;
    top: 50%;
    left: 50%;
}
.media_item{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:15px;
    display:flex;
}
